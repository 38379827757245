import { BasementLevelSpecification } from '../../BasementLevelSpecification';
import * as R from 'ramda';
import { getLevelId } from '../../../../granulometry/levels/queries/getLevelId';
import { CaseProjection } from '../../../../projection/cases/CaseProjection';

export const makeBasementLevels = (
  caseProjection: CaseProjection
): BasementLevelSpecification[] => {
  return R.times((i) => {
    const found = caseProjection.levels.find((l) => l.level === -(i + 1));
    const def = {
      id: getLevelId(caseProjection.id, -(i + 1)),
      level: -(i + 1)
    } as BasementLevelSpecification;
    return found || def;
  }, caseProjection.projectedBasementLevelsCount);
};
