import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../../components/ui/SpecialLabel';
import { FormGroup } from '../../../../../components/ui/FormGroup';
import { Card } from '../../../../ui/Card';
import { Input } from '../../../../ui/input/Input';
import { projectLocationChanged } from '../../../../../store/actions/projectLocationChanged.action';
import { selectProjectLocation } from '../../../../../store/selectors/project/projectLocation.selector';
import {
  PROJECT_LOCATION_DEFAULT_ALTIMETER,
  PROJECT_LOCATION_DEFAULT_CITY,
  PROJECT_LOCATION_DEFAULT_COUNTRY,
  PROJECT_LOCATION_DEFAULT_LATITUDE,
  PROJECT_LOCATION_DEFAULT_LONGITUDE,
  PROJECT_LOCATION_DEFAULT_POSTAL_CODE,
  PROJECT_LOCATION_DEFAULT_STREET,
  ProjectLocation
} from '../../../../../domain/ProjectLocation';
import { selectHasProjectValidLocation } from '../../../../../store/selectors/navigation/toolbox/projectPanel/hasProjectValidLocation.selector';
import { Note } from '../../../../ui/Note';
import { roundWithNoDecimal } from '../../../../../utils/round/roundWithNoDecimal';

export const ProjectLocationCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const location = useSelector(selectProjectLocation);
  const hasValidLocation = useSelector(selectHasProjectValidLocation);

  const handleProjectLocationChange = (location: ProjectLocation) => {
    dispatch(projectLocationChanged(location));
  }

  return (
    <Card>
      <SpecialLabel label={t('Location')} />
      {!hasValidLocation && <Note>{t('projectLocation.defaultLocationNote')}</Note>}
      <FormGroup horizontal={true} wrap={true}>
        <Input
          label={t('Street')}
          name="street"
          type="text"
          value={location.street}
          placeholder={'Adresse par défaut : ' + PROJECT_LOCATION_DEFAULT_STREET}
          handleChange={(event) =>
            handleProjectLocationChange({ ...location, street: event.target.value || '' })
          }
        />
      </FormGroup>
      <FormGroup horizontal={true}>
        <div>
          <Input
            label={t('Postal code')}
            name="postalCode"
            type="text"
            value={location.postalCode}
            placeholder={PROJECT_LOCATION_DEFAULT_POSTAL_CODE}
            handleChange={(event) =>
              handleProjectLocationChange({ ...location, postalCode: event.target.value || '' })
            }
          />
        </div>
        <div>
          <Input
            label={t('City')}
            name="city"
            type="text"
            value={location.city}
            placeholder={PROJECT_LOCATION_DEFAULT_CITY}
            handleChange={(event) =>
              handleProjectLocationChange({ ...location, city: event.target.value || '' })
            }
          />
        </div>
        <div>
          <Input
            label={t('Country')}
            name="country"
            type="text"
            value={location.country}
            placeholder={PROJECT_LOCATION_DEFAULT_COUNTRY}
            handleChange={(event) =>
              handleProjectLocationChange({ ...location, country: event.target.value || '' })
            }
          />
        </div>
      </FormGroup>
      <hr />
      <FormGroup horizontal={true}>
        <div>
          <Input
            label={t('Longitude')}
            name="longitude"
            type="number"
            value={location.longitude}
            placeholder={PROJECT_LOCATION_DEFAULT_LONGITUDE}
            handleChange={(value) =>
              handleProjectLocationChange({ ...location, longitude: value || undefined })
            }
          />
        </div>
        <div>
          <Input
            label={t('Latitude')}
            name="latitude"
            type="number"
            value={location.latitude}
            placeholder={PROJECT_LOCATION_DEFAULT_LATITUDE}
            handleChange={(value) =>
                handleProjectLocationChange({ ...location, latitude: value || undefined })
            }
          />
        </div>
        <div>
          <Input
            label={t('Altitude')}
            name="latitude"
            type="number"
            value={roundWithNoDecimal(location.elevation) }
            placeholder={PROJECT_LOCATION_DEFAULT_ALTIMETER}
            disabled={true}
            className="gb-input--highlighted"
            suffix="m"
          />
        </div>
      </FormGroup>
    </Card>
  );
};
