import { createSelector, Selector } from 'reselect';
import { BasementLevelSpecification } from '../../../../domain/specification/levels/BasementLevelSpecification';
import { makeBasementLevels } from '../../../../domain/specification/levels/queries/make/makeBasementLevels';
import { State } from '../../../reducers';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';

export const selectCurrentCaseSpecifiedBasementLevels: Selector<
  State,
  BasementLevelSpecification[]
> = createSelector([selectCurrentCaseProjection], (caseProjection) =>
  caseProjection !== undefined
    ? (makeBasementLevels(caseProjection) as BasementLevelSpecification[])
    : []
);
