import { Action } from 'redux';
import { ProjectLocation } from '../../domain/ProjectLocation';

type Coordinates = Pick<ProjectLocation, 'latitude' | 'longitude'| 'elevation'>;

export const PROJECT_LOCATION_COORDINATES_FETCHED = 'project/location_coordinates_fetched';

export interface LocationCoordinatesFetched extends Action {
  type: typeof PROJECT_LOCATION_COORDINATES_FETCHED;
  payload: Coordinates;
}
export const projectLocationCoordinatesFetched = (
  coordinates: Coordinates
): LocationCoordinatesFetched => ({
  type: PROJECT_LOCATION_COORDINATES_FETCHED,
  payload: coordinates
});
