import { OTHER_RBS_SECTIONS_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { SectionWithTechnicalSheaths } from '../SectionWithTechnicalSheaths';
import { TechnicalPremiseSectionName } from './TechnicalPremiseSection';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';

interface TechnicalSectionProps {
  surface?: number;
  exposureRate?: number;
  width?: number;
}

export interface TechnicalSection
  extends Section,
    SectionDrawn,
    SectionWithExposureRate,
    SectionWithTechnicalSheaths {
  name: TechnicalPremiseSectionName.TechnicalSection;
  width?: number;
  defaultWidth: number;
  minWidth: undefined;
  maxWidth: undefined;
  defaultSurface: number;
  minSurface: number;
  maxSurface: undefined;
}

export const TECHNICAL_SECTION_DEFAULT_SURFACE = 15;
export const TECHNICAL_SECTION_MIN_SURFACE = 1;
export const TECHNICAL_SECTION_DEFAULT_EXPOSURE_RATE = 1;
export const TECHNICAL_SECTION_DEFAULT_WITH = 3.5;
export const TECHNICAL_SECTION_ICON = {
  type: 'object',
  iconName: 'technical',
  color: OTHER_RBS_SECTIONS_COLOR
} as IconObjectProps;

const technicalSection = ({
  surface,
  exposureRate,
  width
}: TechnicalSectionProps): TechnicalSection =>{
  console.log("TEST SECTION N+0");
  console.log(surface, exposureRate, width);
  return ({
    // Section
    id: 'NOID',
    name: TechnicalPremiseSectionName.TechnicalSection,
    title: i18next.t('technicalSection.title'),
    type: 'others',
    displayedSurface: surface || TECHNICAL_SECTION_DEFAULT_SURFACE,
    // SectionDrawn
    surface: surface || TECHNICAL_SECTION_DEFAULT_SURFACE,
    color: OTHER_RBS_SECTIONS_COLOR,
    icon: TECHNICAL_SECTION_ICON,
    // SectionWithExposureRate
    exposureRate: exposureRate || undefined,
    defaultExposureRate: TECHNICAL_SECTION_DEFAULT_EXPOSURE_RATE,
    // SectionWithTechnicalSheaths
    technicalSheathCount: 0,
    // TechnicalSection
    width: width || undefined,
    defaultWidth: TECHNICAL_SECTION_DEFAULT_WITH,
    minWidth: undefined,
    maxWidth: undefined,
    defaultSurface: TECHNICAL_SECTION_DEFAULT_SURFACE,
    minSurface: TECHNICAL_SECTION_MIN_SURFACE,
    maxSurface: undefined
  });
}

export default technicalSection;

export const isSectionATechnicalSection = (section: Section): section is TechnicalSection =>
  getSectionName(section) === TechnicalPremiseSectionName.TechnicalSection;

export const filterTechnicalSections = (sections: Section[]): TechnicalSection[] =>
  sections.filter(isSectionATechnicalSection);
