import { OTHER_RBS_SECTIONS_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { SectionWithTechnicalSheaths } from '../SectionWithTechnicalSheaths';
import { TechnicalInfraPremiseSectionName } from './TechnicalPremiseSection';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';

interface TechnicalInfraSectionProps {
  surface?: number;
  exposureRate?: number;
  width?: number;
}

export interface TechnicalInfraSection
  extends Section,
    SectionDrawn,
    SectionWithExposureRate,
    SectionWithTechnicalSheaths {
  name: TechnicalInfraPremiseSectionName.TechnicalInfraSection;
  width?: number;
  defaultWidth: number;
  minWidth: undefined;
  maxWidth: undefined;
  defaultSurface: number;
  minSurface: number;
  maxSurface: undefined;
}

export const TECHNICAL_INFRA_SECTION_DEFAULT_SURFACE = 15;
export const TECHNICAL_INFRA_SECTION_MIN_SURFACE = 1;
export const TECHNICAL_INFRA_SECTION_DEFAULT_EXPOSURE_RATE = 1;
export const TECHNICAL_INFRA_SECTION_DEFAULT_WITH = 3.5;
export const TECHNICAL_INFRA_SECTION_ICON = {
  type: 'object',
  iconName: 'technical',
  color: OTHER_RBS_SECTIONS_COLOR
} as IconObjectProps;

const technicalInfraSection = ({
                                 surface,
                                 exposureRate,
                                 width
                               }: TechnicalInfraSectionProps): TechnicalInfraSection => {
  console.log("TEEEEEEEEEEEEEEEEEEST TECHNICAL INFRA SECTION");
  console.log("TYPE : ",
              surface, exposureRate, width

              )
  return ({
    // Section
    id: 'NOID',
    name: TechnicalInfraPremiseSectionName.TechnicalInfraSection,
    title: i18next.t('technicalInfraSection.title'),
    type: 'others',
    displayedSurface: surface || TECHNICAL_INFRA_SECTION_DEFAULT_SURFACE,
    // SectionDrawn
    surface: surface || TECHNICAL_INFRA_SECTION_DEFAULT_SURFACE,
    color: OTHER_RBS_SECTIONS_COLOR,
    icon: TECHNICAL_INFRA_SECTION_ICON,
    // SectionWithExposureRate
    exposureRate: exposureRate || undefined,
    defaultExposureRate: TECHNICAL_INFRA_SECTION_DEFAULT_EXPOSURE_RATE,
    // SectionWithTechnicalSheaths
    technicalSheathCount: 0,
    // TechnicalSection
    width: width || undefined,
    defaultWidth: TECHNICAL_INFRA_SECTION_DEFAULT_WITH,
    minWidth: undefined,
    maxWidth: undefined,
    defaultSurface: TECHNICAL_INFRA_SECTION_DEFAULT_SURFACE,
    minSurface: TECHNICAL_INFRA_SECTION_MIN_SURFACE,
    maxSurface: undefined
  });
//}
}

export default technicalInfraSection;

export const isSectionATechnicalInfraSection = (
  section: Section
): section is TechnicalInfraSection =>
  getSectionName(section) === TechnicalInfraPremiseSectionName.TechnicalInfraSection;

export const filterTechnicalInfraSections = (sections: Section[]): TechnicalInfraSection[] =>
  sections.filter(isSectionATechnicalInfraSection);
