import axios from 'axios';

interface returnFromOpenDataAPI{
  elevation : number,
  latitude : number,
  longitude : number
}

export const getElevationFromOpenDataAPI = async (long: number, lat: number) => {
  try {
    const requete = await axios.get(
      "https://api.elevationapi.com/api/Elevation?lat=" + lat.toString() + "&lon=" + long.toString()
    );

    if(requete.status !== 200) {
      console.error(`Erreur: ${requete.status}`);
      return null;
    }

    if(!requete.data.geoPoints){
      console.error(`Erreur: impossible de récuperer l'altitude`);
      return null;
    }

    if(requete.data.geoPoints.length < 1){
      console.error(`Erreur: impossible de récuperer l'altitude`);
      return null;
    }

    const coordonnees : returnFromOpenDataAPI = requete.data.geoPoints[0];
    return coordonnees.elevation;

  } catch (e) {
    console.error(e);
  }
}
