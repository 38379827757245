import { createSelector, Selector } from 'reselect';
import { State } from '../../reducers';
import { selectProjectStreet } from './projectStreet.selector';
import { selectProjectPostalCode } from './projectPostalCode.selector';
import { selectProjectCity } from './projectCity.selector';
import { selectProjectCountry } from './projectCountry.selector';
import { selectProjectLongitude } from './projectLongitude.selector';
import { selectProjectLatitude } from './projectLatitude.selector';
import { ProjectLocation } from '../../../domain/ProjectLocation';
import { selectProjectElevation } from './projectElevation.selector';

export const selectProjectLocation: Selector<State, ProjectLocation> = createSelector(
  [
    selectProjectStreet,
    selectProjectPostalCode,
    selectProjectCity,
    selectProjectCountry,
    selectProjectLongitude,
    selectProjectLatitude,
    selectProjectElevation
  ],
  (street, postalCode, city, country, longitude, latitude, elevation) => ({
    street,
    postalCode,
    city,
    country,
    longitude,
    latitude,
    elevation
  })
);
