import { Epic, ofType } from 'redux-observable';
import { Action } from 'redux';
import { State } from '../reducers';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import {
  GB_PROJECT_LOCATION_CHANGED,
  ProjectLocationChangedAction
} from '../actions/projectLocationChanged.action';
import { projectLocationCoordinatesFetched } from '../actions/projectLocationCoordinatesFetched';
import { from, of } from 'rxjs';
import getAddressCoordinates, {
  GeoCodingFeature
} from '../../services/mapBox/REST/getAddressCoordinates';
import { projectFetchFailed } from '../actions/projectsFetchFailed.action';

export const getGeoCoding: Epic<Action, Action, State> = (actions$) =>
  actions$.pipe(
    ofType(GB_PROJECT_LOCATION_CHANGED),
    debounceTime(1000),
    switchMap((action: ProjectLocationChangedAction) => {
      return from(getAddressCoordinates(action.payload)).pipe(
        filter((res) => res.length !== 0),
        map((res: GeoCodingFeature[]) => {
          return projectLocationCoordinatesFetched({
            latitude: res[0].center[0],
            longitude: res[0].center[1],
            elevation: res[0].center[2]
          });
        }),
        catchError(() => {
          alert(
            'Impossible de récupérer les coordonnées GPS de votre projet, veuillez les entrer manuellement'
          );
          return of(projectFetchFailed());
        })
      );
    })
  );
